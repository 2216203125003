@import './settings.scss';

*, *::before, *::after {
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  display: none;
}

.main__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  cursor: default;
  scroll-behavior: smooth;
}

body {
  background-color: $black-main;
  font-family: $font-text-5;
  margin: 0;
  padding: 0;
  width: 100%;
}

a {
  text-decoration: none !important;

  &:link {
    text-decoration: none !important;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: rgb(230, 230, 230);
}
