@import '../../styles/settings.scss';

.collections {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.home__collections__container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
}

.home__collections__box {
  width: 100%;
  border-radius: 0.3rem;
  padding: 0.15rem;
  background-color: $gray-border;
  box-shadow: 0 0.2rem 0.5rem rgb(0, 0, 0);
  padding-bottom: 0;
  cursor: pointer;

  &:hover {
    background-color: $red-main;
  }
}

.home__collections__poster img {
  width: 100%;
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0.5rem rgb(0, 0, 0);
}

@media (max-width: 1024px) {

  .collections {
    margin-top: 0;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }

  .home__collections__container {
    gap: 0.5rem;
  }

  .home__collections__box {
    width: fit-content;
  }
}

@media (max-width: 540px) {

  .home__collections__container {
    gap: 0.3rem;
    flex-wrap: wrap;
  }

  .home__collections__box {
    width: 32%;
  }
}
