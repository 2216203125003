.home__container {
  display: flex;
  flex-direction: column;
  padding-top : 8rem;

  h2 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 0.3rem;
  }
}

.page__input {
  width: fit-content;
  background-color: rgb(68, 68, 68);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  color: white;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

@media(max-width: 1024px) {
  .home__container {
    padding-top : 6rem;
  }
}

@media (max-width: 540px) {
  .home__container {
    padding-top : 4.6rem;
  }
}
