@import '../../styles/settings.scss';

li {
  list-style: none;
}

.dropdown__toggle__btn {
  display: none;
}

.navbar__dropdown {
  display: none;
}

.navbar__logo {
  font-family: $font-text-1;
  line-height: 1rem;
  left: 0;
  top: -1.5rem;
  cursor: pointer;

  span {
    img {
      margin-bottom: -0.7rem;
      margin-right: -0.5rem;
      margin-left: 0.05rem;
      width: 2.5rem;
      filter: invert(100%);
      opacity: 0.7;
    }
  }
}

.logo__font {
  position: relative;
  margin: 1.7rem 0;
  width: 30%;
  box-shadow: 0rem -5rem 10rem 5rem #000000e1;
}

.logo__font h1 {
  font-size: 2.1rem;
  font-weight: 100;
  position: absolute;
  letter-spacing: 0.2rem;
}

.logo__font h1:nth-child(1) {
  border-radius: 50%;
  color: transparent;
  -webkit-text-stroke: 4px rgb(194, 54, 22);
}

.logo__font h1:nth-child(2) {
  color: #000000;
}

.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  padding-bottom: 0;
  position: fixed;
  z-index: 999;
  background-color: hsl(0, 0%, 9%);
}

.navbar__pattern {
  width: 100%;
  height: 0.01rem;
  background-color: rgba(34, 32, 28, 0.950);
  opacity: 1;
  margin-top: 1rem;
  filter: brightness(2);
  border-radius: 2rem;
}

.pattern__off {
  display: none;
}

.navbar__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navbar__log__search{
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.navbar__logged__user {
  display: flex;
  flex-direction: column;

  h2 {
    color: white;
  }
}

.navbar__login__button {
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 1rem;
  background-color: rgb(158, 33, 33);
  font-family: $font-text-4;
  font-weight: 500;
  color: $white;
  font-size: 1rem;
  cursor: pointer;
  z-index: 999;
  text-shadow: 0.1rem 0.1rem 0.1rem black;
}

.navbar__links {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-left: -25%;

  a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1.5rem;
  }

  .active {
    color: $red-main;
  }
}

.navbar__search__icon__desktop {
  cursor: pointer;
  width: 1.5rem;
  opacity: 0.8;
  filter: invert(100%);
}

@media (max-width: 1024px) {
  .navbar__links {
    display: none;
  }

  .navbar__search__icon__desktop {
    display: none;
  }

  .navbar {
    padding: 1.5rem;
    padding-bottom: 0;
  }

.logo__font {
  margin: 1.7rem 0;
  width: 100%;
}

  .navbar__logo {
    text-align: end;
    left: 45%;
  }

  .navbar__pattern {
    margin-top: 1rem;
  }

  .navbar__wrapper {
    justify-content: space-between;
  }

  .navbar__login__button {
    background-color: transparent;
    width: 3.1rem;
    min-height: 1.7rem;
    padding: 0;
    font-size: 0.9rem;
  }

  .navbar__search__icon__mobile {
    margin-left: 10%;
    cursor: pointer;
    max-width: 2.3rem;
    opacity: 1;
    filter: invert(100%);
  }

  .dropdown__toggle__btn {
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.2rem;
    width: 1.7rem;
    height: 2rem;

    .hamburger__one {
      transition: all 0.3s ease-in-out;
      background-color: $white;
      height: 0.3rem;
      width: 115%;
      border-radius: 1rem;
    }

    .hamburger__two {
      transition: all 0.3s ease-in-out;
      background-color: $white;
      height: 0.3rem;
      width: 115%;
      border-radius: 1rem;
    }

    .hamburger__three {
      background-color: $white;
      height: 0.3rem;
      width: 115%;
      border-radius: 1rem;
    }
  }

  .dropdown__toggle__btn.active {
    z-index: 3;
    transition: all 0.3s ease-in-out;

    .hamburger__one {
      transition: all 0.3s ease-in-out;
      background-color: rgb(194, 54, 22);
      transform: rotate(45deg);
      border-radius: 1rem;
      margin-top: 0rem;
      margin-bottom: -0.5rem;
      width: 140%;
    }

    .hamburger__two {
      transition: all 0.3s ease-in-out;
      background-color: rgb(194, 54, 22);
      transform: rotate(-45deg);
      border-radius: 1rem;
      width: 140%;
    }

    .hamburger__three {
      display: none;
    }
  }

  .navbar__dropdown {
    position: absolute;
    min-width: 50%;
    background-color: rgba(34, 32, 28, 0.950);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 1rem;
    padding-left: 5.5rem;
    padding-top: 7rem;
    padding-right: 2rem;
    margin-left: -5rem;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5rem;
    top: -5.6rem;
    left: 5rem;
    z-index: 2;
    box-shadow: 0 0 20rem black;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;

    &.open {
      transform: translateX(0);
    }

    img {
      width: 3rem;
      opacity: 0.9;
    }

    .dropdown__categories__icon {
      filter: invert(100%);

      img {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 540px) {

  .navbar__logo {
    font-family: $font-text-1;
    line-height: 0.5rem;
    left: 38%;
    top: -1.1rem;
    cursor: pointer;

    span {
      img {
        margin-bottom: -0.55rem;
        margin-right: -0.45rem;
        margin-left: 0.025rem;
        width: 2rem;
      }
    }
  }

  .logo__font {
    margin: 1.1rem 0;
    width: 50%;
  }

  .logo__font h1 {
    font-size: 1.6rem;
  }

  .logo__font h1:nth-child(1) {
    -webkit-text-stroke: 3px rgb(194, 54, 22);
  }

  .navbar__dropdown {
    min-width: 23%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    left: 0;
    padding-left: 1.3rem;
    padding-right: 0;
    margin-left: 0;
    transition: all 0.3s ease-in-out;
  }
}

  .linkActive {
    filter: invert(60%) sepia(10%) saturate(2500%) hue-rotate(320deg) brightness(90%) contrast(160%);
  }

  .linkActiveCategories {
    filter: invert(60%) sepia(10%) saturate(2500%) hue-rotate(320deg) brightness(65%) contrast(160%);
  }

  .linkActiveSearch {
    filter: invert(60%) sepia(10%) saturate(2500%) hue-rotate(320deg) brightness(95%) contrast(160%);
  }

