@import '../../styles/settings.scss';

.carousel__wrapper {
  z-index: 99;
}

.carousel__box {
  padding: 0;

  img {
    width: 100%;
    aspect-ratio: 16 / 9;
    min-height: 100%;
    max-height: 40rem !important;
    object-fit: cover;
    object-position: top;
    cursor: pointer;
  }
}

.image__border {
  padding: 0.1rem 0;
  border-radius: 0rem;
  background-color: rgb(47, 54, 64);
  box-shadow: 0 0.4rem 0.5rem rgb(0, 0, 0);
}

.title__container {
  position: absolute;
  bottom: 3%;
  left: 3%;
  width: 100%;
}

.title__box p {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: $font-text-4;
  font-weight: 700;
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  color: $white;
  opacity: 0.95;
}

.image__box {
  position: relative;
}

//  SLICK ARROWS
.slick-next {
  right: 0;
  height: 100% !important;
  width: 5rem !important;
  z-index: 9;
  opacity: 0.5;
  cursor: pointer;
}

.slick-prev {
  left: 1px;
  height: 100% !important;
  width: 5rem !important;
  z-index: 9;
  opacity: 0.5;
  cursor: pointer;
}

@media (max-width: 1024px) {

  .carousel__box {
    padding: 0rem;
    padding-top: 1rem;
  }

  .title__box {
    p {
      font-size: 1rem;
    }
  }
}

.slick-next {
  right: 0;
  height: 100% !important;
  width: 2.5rem !important;
  z-index: 9;
  opacity: 0.5;
  cursor: pointer;
}

.slick-prev {
  left: 0;
  height: 100% !important;
  width: 2.5rem !important;
  z-index: 9;
  opacity: 0.5;
  cursor: pointer;
}

.slick-list {
  padding-bottom: 1rem;
}
