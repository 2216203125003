.footer__container {
  padding: 1rem;
  padding-bottom: 2.5rem;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  z-index: 999;
}

.tmdb__logo {
  width: 20rem;
}

.footer__desc {
  text-align: center;
  opacity: 0.7;
  width: 80%;
  font-size: 0.7rem;
}

@media (max-width: 1024px) {
  .tmdb__logo {
    width: 10rem;
  }

  .footer__container {
    padding-top: 1rem;
  }
}
