@import '../../styles/settings.scss';

.hubs {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.collections__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 0.7rem 2rem;
  background-color: rgba(34, 32, 28, 0.950);
}

.collections__container {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}

.collections__first__four {
  display: flex;
  gap: 1.5rem;
}

.collections__box {
  width: 100%;
  border-radius: 0.3rem;
  padding: 0.1rem;
  background-color: rgb(47, 54, 64);
  box-shadow: 0 0.2rem 0.5rem rgb(0, 0, 0);
  cursor: pointer;

  &:hover {
    background-color: $red-main;
  }
}

.collections__img {
  width: 100%;
  position: absolute;
  top: 0;
  border-radius: 0.3rem;
  z-index: 10;
  aspect-ratio: 16/9;
}

.collections__logo {
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  aspect-ratio: 16/9;
}

.collections__video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.2rem;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.collections__mobile__background {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.2rem;
  aspect-ratio: 16/9;
  object-fit: cover;
  filter: brightness(0.9)
}

.collections__main__title {
  position: relative;
  margin-top: 1.8rem;
  width: 100%;
  font-family: $font-text-1;
  text-align: center;
}

.collections__main__title h1 {
  font-size: 2rem;
  font-weight: 100;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  letter-spacing: 0.2rem;
  padding-bottom: 0.15rem;
}


.collections__main__title h1:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 3px $white;
  box-shadow: 0 1rem 1rem#000000;
  width: 100%;
}

.collections__main__title h1:nth-child(2) {
  color: #000000;
  width: 100%;
}

@media (max-width: 1024px) {

  .collections__video {
    display: none;
  }

  .collections__mobile__background {
    display: flex;
  }

  .hubs {
    margin-top: -0.7rem;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }

  .collections__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    padding: 0.8rem 0.5rem;
    background-color: rgba(34, 32, 28, 0.950);
  }

  .collections__container {
    display: flex;
    gap: 0.3rem;
  }

  .collections__box {
    padding: 0.1rem;
    opacity: 1;
  }

  .collections__main__title {
    margin-top: 1.8rem;
  }

  .collections__main__title h1 {
    font-size: 1.5rem;
  }

  .collections__main__title h1:nth-child(1) {
    box-shadow: 0 0.5rem 0.5rem#000000;
  }
}

@media (max-width: 540px) {
  
  .collections__wrapper {
    flex-direction: column;
  }
}
